import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../SideBar/SideBar";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

interface LayProps {
  setIsCreate?: any;
}

const Layout = (props: LayProps) => {
  const [openMenu, setOpenMenu] = useState(true);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const { setIsCreate } = props;
  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };
 

  console.log(openSideBar,'openSideBar')

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <Sidebar openMenu={openMenu} setOpenSideBar={setOpenSideBar} openSideBar={openSideBar}  menuBtnEvent={handleMenu} />
      <Box className="pageWrapper">
        <Header
          menuEvent={handleMenu}
          openMenu={openMenu}
          showLogout={showLogout}
          setIsCreate={setIsCreate}
          setOpenSideBar={setOpenSideBar}
        />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
