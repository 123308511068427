import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LeadService from "../Service/LeadsService";

const initialState: any = {
  leads: [],
  isFetching: false,
  error: "",
  isError: false,
  totalPages: 0,
  lead: {},
};

export interface LeadCreate {
  firstName: string;
  leadStatus: string;
  lastName: string;
  phone: string;
  company: string;
  email: string;
  title: string;
  rating: string;
  followUp: boolean;
  street: string;
  website: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  noOfEmployee: string;
  leadSource: string;
  annualRevenue: string;
  industry: string;
  description: string;
  document?: string;
}

export const allLeads: any = createAsyncThunk(
  "all/leads",
  async ({ page, limit }: { page: number; limit: number }, thunkAPI) => {
    const data = await LeadService.allLeads(page, limit);

    if (data) return data;
    else return thunkAPI.rejectWithValue("Error on getting all users");
  }
);

export const deleteLeads: any = createAsyncThunk(
  "delete/lead",
  async ({ id }: { id: string }, thunkAPI) => {
    const data = await LeadService.deleteLeads(id);

    if (data) return data;
    else return thunkAPI.rejectWithValue("Error on getting all users");
  }
);

export const newLeads: any = createAsyncThunk(
  "post/newLead",
  async ({ leadData }: { leadData: LeadCreate }, thunkAPI) => {
    const data = await LeadService.newLeads(leadData);

    if (data) return data;
    else return thunkAPI.rejectWithValue("Error on getting all users");
  }
);

export const updateLead: any = createAsyncThunk(
  "update/Lead",
  async ({ leadData, id }: { leadData: LeadCreate; id: string }, thunkAPI) => {
    const data = await LeadService.updateLead(leadData, id);

    if (data) return data;
    else return thunkAPI.rejectWithValue("Error on getting all users");
  }
);

export const leadById: any = createAsyncThunk(
  "lead/byId",
  async ({ id }: { id: string }, thunkAPI) => {
    const data = await LeadService.leadById(id);

    if (data) return data;
    else return thunkAPI.rejectWithValue("Error on getting all users");
  }
);

export const updateLeadStatus: any = createAsyncThunk(
  "update/LeadStatus",
  async ({ status, id }: { status: string; id: string }, thunkAPI) => {
    const data = await LeadService.updateLeadStatus(status, id);

    if (data) return data;
    else return thunkAPI.rejectWithValue("Error on getting all users");
  }
);

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isError = false;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allLeads.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
      })
      .addCase(allLeads.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isError = false;
        state.error = "";
        state.leads = action.payload?.Leads;
        state.totalPages = action.payload?.totalPages;
      })
      .addCase(allLeads.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.error = action.payload as string;
      })
      .addCase(newLeads.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
      })
      .addCase(newLeads.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isError = false;
        state.error = action.payload?.response?.data?.message;
      })
      .addCase(newLeads.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.error = action.payload;
      })
      .addCase(deleteLeads.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
      })
      .addCase(deleteLeads.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isError = false;
        state.error = "";
      })
      .addCase(deleteLeads.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.error = action.payload as string;
      })
      .addCase(leadById.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
      })
      .addCase(leadById.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isError = false;
        state.error = "";
        state.lead = action.payload?.Lead;
      })
      .addCase(leadById.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.error = action.payload as string;
      })
      .addCase(updateLeadStatus.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
      })
      .addCase(updateLeadStatus.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isError = false;
        state.error = "";
      })
      .addCase(updateLeadStatus.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.error = action.payload as string;
      })
      .addCase(updateLead.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
      })
      .addCase(updateLead.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isError = false;
        state.error = "";
      })
      .addCase(updateLead.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.error = action.payload as string;
      });
  },
});

export const { clearState } = leadsSlice.actions;

export default leadsSlice;
