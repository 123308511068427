import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { RootState } from "../../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { NextButton } from "../../../Helper/button/NextButton/NextButton";
import { allLeads, deleteLeads } from "../../../Redux/Features/Lead-Slice";
import { OutlineButton } from "../../../Helper/button/OutlineButton/OutlineButton";

interface DeleteLeadModalInterface {
  open: boolean;
  onClose: Function;
  lead: any | undefined;
  page: number;
}

export default function LeadDeleteModal(props: DeleteLeadModalInterface) {
  const { onClose, open, lead } = props;
  const dispatch = useDispatch();

  const deleteData = useSelector((state: RootState) => state.leads);

  // const { t } = useTranslation("company");
  const { t } = useTranslation();

  const handleDeleteLead = async () => {
    try {
      if (lead) {
        await dispatch(deleteLeads({ id: lead })).unwrap();
        onClose?.();
        await dispatch(allLeads({ page: 1, limit: 5 })).unwrap();
      }
    } catch (error) {}
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose?.()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableScrollLock={true}
      PaperProps={{
        style: {
          borderRadius: "48px",
          maxWidth: "550px",
          width: "100%",
        },
      }}
      className="deleteModel"
    >
      <Box className="deleteModel__inner">
        <Typography variant="h6">{t("lang.CancelConfirmation")}</Typography>
        <Typography>{t("lang.CancelSure")}</Typography>
        <Box>
          <div className="deleteModel__footer">
            <OutlineButton
              style={{
                margin: 0,
                fontWeight: 600,
                background: "#2659ed",
                color: "#fff",
              }}
              onClick={() => onClose?.()}
            >
              {t("lang.Cancel")}
            </OutlineButton>
            <NextButton
              disabled={deleteData?.isFetching}
              loading={deleteData?.isFetching}
              style={{ backgroundColor: "red" }}
              type="submit"
              onClick={handleDeleteLead}
            >
              {t("lang.Delete")}
            </NextButton>
          </div>
        </Box>
      </Box>
    </Dialog>
  );
}
