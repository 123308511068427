import React, { useState } from "react";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";

import "../Leads.scss";
import LeadDialog from "../LeadDialog";
import { validateLead } from "../Lead.Validate";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { allLeads, newLeads } from "../../../../Redux/Features/Lead-Slice";

interface CreateProps {
  isCreate: boolean;
  setIsCreate: Function;
}

const CreateLead = (props: CreateProps) => {
  const { isCreate, setIsCreate } = props;
  const [SaveOrSaveAndNew, setSaveOrSaveAndNew] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div>
      <Formik<any>
        initialValues={{
          firstName: "",
          leadStatus: "",
          lastName: "",
          phone: "",
          company: "",
          email: "",
          title: "",
          rating: "",
          followUp: false,
          street: "",
          website: "",
          city: "",
          state: "",
          zipCode: "",
          country: "",
          noOfEmployee: "",
          leadSource: "",
          annualRevenue: "",
          industry: "",
          description: "",
          document: "",
        }}
        onSubmit={(values, { resetForm }) => {
          dispatch(
            newLeads({
              leadData: {
                firstName: values?.firstName,
                leadStatus: values?.leadStatus,
                lastName: values?.lastName,
                phone: values?.phone,
                company: values?.company,
                email: values?.email,
                title: values?.title,
                rating: values?.rating,
                followUp: values?.followUp,
                street: values?.street,
                website: values?.website,
                city: values?.city,
                state: values?.state,
                zipCode: values?.zipCode,
                country: values?.country,
                noOfEmployee: values?.noOfEmployee,
                leadSource: values?.leadSource,
                annualRevenue: values?.annualRevenue,
                industry: values?.industry,
                description: values?.description,
                document: values?.document,
              },
            })
          ).then((data: any) => {
            if (data?.payload?.success) {
              resetForm();
              dispatch(allLeads({ page: 1, limit: 5 }));
              if (SaveOrSaveAndNew === "Save") {
                setIsCreate(false);
              }
            } else {
            }
          });
        }}
        validate={(values) => validateLead(values, t)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isValid,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box className="createFormData createNewForm">
              <LeadDialog
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isCreate={isCreate}
                setIsCreate={setIsCreate}
                setSaveOrSaveAndNew={setSaveOrSaveAndNew}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateLead;
