import { Skeleton, TableCell, TableBody, TableRow } from "@mui/material";

interface TableLoadingSkeletonProps {
  colSpan: number;
  rowsPerPage: number;
}

export const TableLoadingSkeleton = (props: TableLoadingSkeletonProps) => {
  const { colSpan, rowsPerPage } = props;
  const rows = [...Array(rowsPerPage)].map((_, i) => (
    <TableRow key={i}>
      <TableCell
        sx={{
          height: 30,
          padding: 0,
        }}
        colSpan={colSpan}
        key={i}
      >
        <Skeleton
          animation="wave"
          sx={{
            backgroundColor: "#eeeeee",
            height: 42,
          }}
        />
      </TableCell>
    </TableRow>
  ));
  return <TableBody>{rows}</TableBody>;
};
