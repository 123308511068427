import * as React from "react";
import Dialog from "@mui/material/Dialog";

import { Box, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store";
import { OutlineButton } from "../../../../Helper/button/OutlineButton/OutlineButton";
import { NextButton } from "../../../../Helper/button/NextButton/NextButton";
import {
  leadById,
  updateLeadStatus,
} from "../../../../Redux/Features/Lead-Slice";

interface DeleteLeadModalInterface {
  open: boolean;
  onClose: Function;
  lead: any | undefined;
  status: string;
}

export default function LeadStatusModal(props: DeleteLeadModalInterface) {
  const { onClose, open, lead, status } = props;
  const dispatch = useDispatch();
  //   const { viewState: CompanyListState } = useSelector((state: AppState) => state.CompanyList);
  // const { viewState} = useSelector((state: RootState) => state.leadEdit);
  const { t } = useTranslation();
  const updateLead = useSelector((state: RootState) => state?.leads);

  const handleStatusLead = async () => {
    try {
      if (lead) {
        await dispatch(
          updateLeadStatus({
            id: lead,
            status: status,
          })
        ).unwrap();
        onClose?.();
        await dispatch(leadById({ id: lead })).unwrap();
      }
    } catch (error) {}
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose?.()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableScrollLock={true}
      PaperProps={{
        style: {
          borderRadius: "48px",
          maxWidth: "550px",
          width: "100%",
        },
      }}
      className="deleteModel"
    >
      <Box className="deleteModel__inner">
        <Typography variant="h6">{t("lang.CancelConfirmation")}</Typography>
        <Typography>{t("lang.CancelSure")}</Typography>
        <Box>
          <div className="deleteModel__footer">
            <OutlineButton
              style={{
                margin: 0,
                fontWeight: 600,
                background: "#2659ed",
                color: "#fff",
              }}
              onClick={() => onClose?.()}
            >
              {t("lang.Cancel")}
            </OutlineButton>
            <NextButton
              disabled={updateLead?.isFetching}
              loading={updateLead?.isFetching}
              style={{ backgroundColor: "green !important" }}
              type="submit"
              onClick={handleStatusLead}
            >
              {t("lang.Update")}
            </NextButton>
          </div>
        </Box>
      </Box>
    </Dialog>
  );
}
