import { useState } from "react";

import {
  Box,
  Avatar,
  Divider,
  Popover,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";

import { alpha } from "@mui/material/styles";
import {
  LogoutSharp,
  Settings,
  Language,
  ArrowBack,
} from "@mui/icons-material";
import "./Account-Popover.scss";
import { logout } from "../../Redux/Features/Auth-Slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../Redux/Store";
import { useTranslation } from "react-i18next";

export type UserAccount = {
  displayName: string;
  email: string;
  photoURL: string;
};

export const account: UserAccount = {
  displayName: "Jaydon Frankie",
  email: "demo@minimals.cc",
  photoURL: "/assets/images/avatars/avatar_25.jpg",
};

const AccountPopover = (profile: any) => {
  const [open, setOpen] = useState<any>();
  const [IsLanguage, setIsLanguage] = useState<boolean>(false);
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(null);
    setIsLanguage(false);
  };

  const handleLogout = () => {
    dispatch(logout()).then((res: any) => {
      if (res?.payload === true) {
        navigate("/signin");
      }
    });
  };

  function handleLanguageChange(value: string) {
    i18n.changeLanguage(value);
  }

  const { i18n } = useTranslation();
  const { t } = useTranslation();

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 51,
          height: 51,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={profile?.profile?.photoURL}
          alt={profile?.profile?.name}
          sx={{
            width: 51,
            height: 51,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {profile?.profile?.name?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            width: 250,
          },
        }}
      >
        {IsLanguage ? (
          <>
            {/* <Divider sx={{ borderStyle: "dashed" }} /> */}
            <Box
              sx={{
                my: 1.5,
                px: 2,
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <ArrowBack
                sx={{ color: "#2659ED", Size: "18px" }}
                onClick={() => setIsLanguage(false)}
              />
              <Typography
                variant="subtitle2"
                noWrap
                sx={{ color: "#2659ED", paddingLeft: "5px", fontSize: "18px" }}
              >
                {t("lang.Language")}
              </Typography>
            </Box>
            <ul className="ProfileTab">
              <MenuItem
                key={"English"}
                onClick={() => handleLanguageChange("en")}
              >
                {t("lang.English")}
              </MenuItem>
              {/* <Divider sx={{ borderStyle: "dashed" }} /> */}
              <MenuItem
                key={"French"}
                onClick={() => handleLanguageChange("fr")}
              >
                {t("lang.French")}
              </MenuItem>
              {/* <Divider sx={{ borderStyle: "dashed" }} /> */}
              <MenuItem
                key={"Spanish"}
                onClick={() => handleLanguageChange("es")}
              >
                {t("lang.Spanish")}
              </MenuItem>
            </ul>
          </>
        ) : (
          <>
            <Box className="ProfileDrp">
              <Avatar
                src={profile?.profile?.photoURL}
                alt={profile?.profile?.name}
                sx={{
                  width: 36,
                  height: 36,
                  border: (theme) =>
                    `solid 2px ${theme.palette.background.default}`,
                }}
              >
                {profile?.profile?.name?.charAt(0).toUpperCase()}
              </Avatar>

              <Typography variant="subtitle2" noWrap>
                {profile?.profile?.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary" }}
                noWrap
              >
                {profile?.profile?.email}
              </Typography>
            </Box>
            <ul className="ProfileTab">
              {/* <Divider sx={{ borderStyle: "dashed" }} /> */}
              {/* <MenuItem
                key={"Settings"}
                onClick={handleClose}
                sx={{ display: "flex", gap: 2 }}
              >
                <Settings />
                {t("lang.Settings")}
              </MenuItem> */}
              <MenuItem
                key={"Language"}
                onClick={() => setIsLanguage(true)}
                sx={{ display: "flex", gap: 2 }}
              >
                <Language />
                {t("lang.Language")}
              </MenuItem>
              <MenuItem
                key={"Logout"}
                onClick={handleLogout}
                sx={{ display: "flex", gap: 2 }}
              >
                <LogoutSharp />
                {t("lang.Logout")}
              </MenuItem>
            </ul>
          </>
        )}
      </Popover>
    </>
  );
};

export default AccountPopover;
