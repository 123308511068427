import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_URL;

export interface LeadCreate {
  firstName: string;
  leadStatus: string;
  lastName: string;
  phone: string;
  company: string;
  email: string;
  title: string;
  rating: string;
  followUp: boolean;
  street: string;
  website: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  noOfEmployee: string;
  leadSource: string;
  annualRevenue: string;
  industry: string;
  description: string;
  document?: string;
}

const allLeads = async (page: number, limit: number) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/leads/getAllLeads?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: localStorage.getItem("bearerToken"),
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

const deleteLeads = async (id: string) => {
  try {
    let { data } = await axios.delete(`${baseURL}/leads/deleteLeads/${id}`, {
      headers: {
        Authorization: localStorage.getItem("bearerToken"),
      },
    });
    return data;
  } catch (e) {
    return e;
  }
};

const newLeads = async (leadData: LeadCreate) => {
  try {
    const formData = new FormData();
    Object.entries(leadData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await axios.post(
      `${baseURL}/leads/createdNewLeads`,
      formData,
      {
        headers: {
          Authorization: localStorage.getItem("bearerToken"),
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (e) {
    return e;
  }
};

const updateLead = async (leadData: LeadCreate, id: string) => {
  try {
    const formData = new FormData();
    Object.entries(leadData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await axios.put(
      `${baseURL}/leads/updateLeads/${id}`,
      formData,
      {
        headers: {
          Authorization: localStorage.getItem("bearerToken"),
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (e) {
    return e;
  }
};

const leadById = async (id: string) => {
  try {
    let { data } = await axios.get(`${baseURL}/leads/getLeadsById/${id}`, {
      headers: {
        Authorization: localStorage.getItem("bearerToken"),
      },
    });
    return data;
  } catch (e) {
    return e;
  }
};

const updateLeadStatus = async (status: string, id: string) => {
  try {
    const formData = new FormData();

    formData.append("leadStatus", status);

    const response = await axios.put(
      `${baseURL}/leads/updateLeads/${id}`,
      formData,
      {
        headers: {
          Authorization: localStorage.getItem("bearerToken"),
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (e) {
    return e;
  }
};

//Export Auth Service
const LeadService = {
  allLeads,
  deleteLeads,
  newLeads,
  updateLead,
  leadById,
  updateLeadStatus,
};

export default LeadService;
