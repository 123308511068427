import { Outlet, Navigate } from "react-router-dom";

const PublicRoute = () => {
  const arrayString = localStorage.getItem("bearerToken");
  let userExist: any = {};
  userExist = arrayString && arrayString !== "undefined" ? arrayString : "";

  return userExist ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoute;
