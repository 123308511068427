import React, { useState } from "react";
import { Form, Formik } from "formik";

import { useDispatch } from "react-redux";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { validateAuth } from "./SignIn.Validation";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../../Redux/Features/Auth-Slice";
import XangeLogo from "../../../Assets/Images/SVG/xange-logo.svg";
import { Box, Button, TextField, Typography } from "@mui/material";
import { validationError } from "../../../Constraints/Shared.Validation";
import "./SignIn.scss";

export interface LoginValues {
  username: string;
  password: string;
}

const SignIn = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [msg, setMsg] = useState("");
  return (
    <Box>
      <Box className="containerSignin">
        <Box className="containerSignin__details">
          <Box>
            <img src={XangeLogo} alt="" />
          </Box>

          {msg ? (
            <Box className="containerSignin__errorContainer">
              <Box className="error">
                <Box className="errorDetails">
                  <Box className="errorText">
                    <Typography>Password/Username is incorrect!</Typography>
                  </Box>
                  <Close
                    onClick={() => setMsg("")}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            ""
          )}

          <Formik<LoginValues>
            validateOnBlur
            initialValues={{
              username: "",
              password: "",
            }}
            onSubmit={(values) => {
              dispatch(
                login({
                  username: values.username,
                  password: values.password,
                })
              ).then((res: any) => {
                if (res.payload?.success) {
                  navigate("/");
                } else {
                  setMsg(res?.payload?.message);
                }
              });
            }}
            validate={(values) => validateAuth(values, t)}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
            }) => (
              <Form onSubmit={handleSubmit} className="form">
                <TextField
                  id="Email"
                  label="Username"
                  variant="filled"
                  name="username"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(touched.username) && Boolean(errors.username)}
                  helperText={validationError(
                    errors.username,
                    touched.username
                  )}
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <TextField
                  id="Password"
                  label="Password"
                  type="password"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="password"
                  error={Boolean(touched.password) && Boolean(errors.password)}
                  helperText={validationError(
                    errors.password,
                    touched.password
                  )}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Box>
                  <Link to="/forgot-password/request-otp" className="forget">
                    Forgot Password?
                  </Link>
                </Box>
                <Box className="btn">
                  <Button
                    variant="contained"
                    className="form__formBtn"
                    type="submit"
                  >
                    Login
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default SignIn;
