import { StandardTextFieldProps, SxProps, TextField } from "@mui/material";
import { Theme } from "@emotion/react";

interface OutlineTextFieldProps extends StandardTextFieldProps {}

export const OutlineTextField: React.FC<OutlineTextFieldProps> = ({
  sx,
  InputProps,
  multiline,
  ...rest
}) => {
  const sxProps: SxProps<Theme> = {
    "& .MuiOutlinedInput-root": {
      borderRadius: multiline ? "16px" : "30px",
      border: "transparent",
      backgroundColor: "#fff !important",
      padding: multiline ? "12px 12px !important" : "2px 12px !important",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9E4FF",
    },
    "& .MuiInputBase-input": {
      padding: "4px 12px",
    },
  };
  return (
    <TextField
      name="email"
      onChange={() => {}}
      placeholder={"Search auditors"}
      sx={sxProps}
      InputProps={{
        style: {
          padding: "4px 12px",
        },
        ...InputProps,
      }}
      multiline={multiline}
      {...rest}
    />
  );
};
