import React, { useEffect, useState } from "react";
import "./LeadDetails.scss";
import "./LeadDetails.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Chip, Skeleton, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { NextButton } from "../../../../Helper/button/NextButton/NextButton";
import LeadDetailTitle from "./LeadDetailTitle";
import { useTranslation } from "react-i18next";
import { leadById } from "../../../../Redux/Features/Lead-Slice";
import { RootState } from "../../../../Redux/Store";
import LeadEdit from "../LeadEdit/LeadEdit";
import LeadStatusModal from "../StatusUpdateModal/StatusUpdateModal";

const LeadDetails = (props: any) => {
  const { t } = useTranslation();
  const [RightSelectedTab, setRightSelectedTab] = useState("Activity");
  const [isChipSelected, setChipSelected] = useState(t("lang.LogCall"));
  const dispatch = useDispatch();

  const breadcrumbItems = [
    t("lang.New"),
    t("lang.Contracted"),
    t("lang.Working"),
    t("lang.Unqualified"),
    t("lang.Converted"),
  ];
  // const leadDetail = viewState?.result?.Lead;
  const navigate = useNavigate();
  const { id } = useParams();
  const [isDetailUpdate, setIsDetailUpdate] = useState<any>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");

  const leadDetail = useSelector((state: RootState) => state?.leads);
  const role = localStorage.getItem("role");
  const TextSkeleton = () => (
    <Skeleton variant="text" sx={{ minWidth: "12rem" }} />
  );

  const handleRightTabClick = (tab: any) => {
    setRightSelectedTab(tab);
  };

  useEffect(() => {
    if (id) {
      dispatch(leadById({ id: id }));
    }
  }, [id]);
  return (
    <div>
      <div className="LeadStatus">
        <div className="breadContainer">
          <div className="breadcrumb">
            {breadcrumbItems?.map((status, index) => (
              <a
                key={index}
                className={`${
                  leadDetail?.lead?.leadStatus === status
                    ? "selectedBackground"
                    : ""
                }`}
                onClick={() => {
                  if (
                    leadDetail?.lead?.leadStatus !== status &&
                    role === "ADMINISTRATOR"
                  ) {
                    setOpen(true);
                    setStatus(status);
                  }
                }}
              >
                <span
                  className={`breadcrumb__inner ${
                    leadDetail?.lead?.leadStatus === status
                      ? "blueBackground"
                      : ""
                  }`}
                >
                  <span className="breadcrumb__title">{status}</span>
                </span>
              </a>
            ))}
          </div>
        </div>

        {/* <div className="breadContainerMobile">
          <div className="breadcrumb">
            <div className="breadcrumb__innerBox">
              <div className="breadcrumb__box">
                <span className="breadcrumb__inner">
                  <span className="breadcrumb__title">{t("lang.New")}</span>
                </span>
              </div>
              <div className="breadcrumb__box">
                <span className="breadcrumb__inner">
                  <span className="breadcrumb__title">
                    {t("lang.Contracted")}
                  </span>
                </span>
              </div>
            </div>
            <div className="breadcrumb__innerBox">
              <div className="breadcrumb__box">
                <span className="breadcrumb__inner">
                  <span className="breadcrumb__title">{t("lang.Working")}</span>
                </span>
              </div>
              <div className="breadcrumb__box">
                <span className="breadcrumb__inner">
                  <span className="breadcrumb__title">
                    {t("lang.Unqualified")}
                  </span>
                </span>
              </div>
            </div>
            <div className="breadcrumb__innerBox">
              <div className="breadcrumb__box">
                <span className="breadcrumb__inner">
                  <span className="breadcrumb__title">
                    {t("lang.Converted")}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div> */}

        <button
          className={`LeadStatusBtn ${
            leadDetail?.lead?.leadStatus === "Completed"
              ? "greenBackground"
              : ""
          }`}
          onClick={() => {
            if (
              leadDetail?.lead?.leadStatus !== "Completed" &&
              role === "ADMINISTRATOR"
            ) {
              setOpen(true);
              setStatus("Completed");
            }
          }}
        >
          {t("lang.MarkAsCompleted")}
        </button>
      </div>

      <div className="LeadDetailsBox">
        <div className="LeadDetailLeftBox">
          <div className="LeadDetailEditBox">
            <div className="LeadDetailEditBox_title">
              <p>{t("lang.LeadDetails")}</p>
              {role !== "ADMINISTRATOR" ? (
                ""
              ) : (
                <NextButton onClick={() => setIsDetailUpdate(true)}>
                  {t("lang.Edit")}
                </NextButton>
              )}
            </div>
            <div className="LeadDetail">
              <div className="LeadDetailInsideBox">
                <Typography component="h3">{t("lang.Name")}</Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.firstName +
                    " " +
                    leadDetail?.lead?.lastName
                  )}
                </Typography>
              </div>
              <div className="LeadDetailInsideBox">
                <Typography component="h3">{t("lang.Phone")}</Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.phone
                  )}
                </Typography>
              </div>
              <div className="LeadDetailInsideBox">
                <Typography component="h3">{t("lang.Email")}</Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.email
                  )}
                </Typography>
              </div>
              <div className="LeadDetailInsideBox">
                <Typography component="h3">{t("lang.Company")}</Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.company
                  )}
                </Typography>
              </div>
              <div className="LeadDetailInsideBox">
                <Typography component="h3">{t("lang.Title")}</Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.title
                  )}
                </Typography>
              </div>
              <div className="LeadDetailInsideBox">
                <Typography component="h3">{t("lang.LeadStatus")}</Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.leadStatus
                  )}
                </Typography>
              </div>
            </div>
          </div>

          <div className="LeadDetailEditBox">
            <div className="LeadDetailEditBox_title">{t("lang.Address")}</div>
            <div className="LeadDetail">
              <div className="LeadDetailInsideBox">
                <Typography component="h3">{t("lang.Street")}</Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.street
                  )}
                </Typography>
              </div>
              <div className="LeadDetailInsideBox">
                <Typography component="h3">{t("lang.City")}</Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.city
                  )}
                </Typography>
              </div>
              <div className="LeadDetailInsideBox">
                <Typography component="h3">
                  {t("lang.StateProvince")}
                </Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.state
                  )}
                </Typography>
              </div>
              <div className="LeadDetailInsideBox">
                <Typography component="h3">
                  {t("lang.ZipPostalCode")}
                </Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.zipCode
                  )}
                </Typography>
              </div>
              <div className="LeadDetailInsideBox">
                <Typography component="h3">{t("lang.Country")}</Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.country
                  )}
                </Typography>
              </div>
              <div className="LeadDetailInsideBox">
                <Typography component="h3">{t("lang.Website")}</Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.website
                  )}
                </Typography>
              </div>
            </div>
          </div>

          <div className="LeadDetailEditBox">
            <div className="LeadDetailEditBox_title">
              {t("lang.AdditionalInformation")}
            </div>
            <div className="LeadDetail">
              <div className="LeadDetailInsideBox">
                <Typography component="h3">{t("lang.NoOfEmployee")}</Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.noOfEmployee
                  )}
                </Typography>
              </div>
              <div className="LeadDetailInsideBox">
                <Typography component="h3">
                  {t("lang.AnnualRevenue")}
                </Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.annualRevenue
                  )}
                </Typography>
              </div>
              <div className="LeadDetailInsideBox">
                <Typography component="h3">{t("lang.LeadSource")}</Typography>
                <Typography>
                  {leadDetail?.isFetching ? (
                    <TextSkeleton />
                  ) : (
                    leadDetail?.lead?.leadSource
                  )}
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="LeadDetailRightBox">
          <div className="DetailsOrMarketing">
            <div
              onClick={() => handleRightTabClick("Activity")}
              className={
                RightSelectedTab === "Activity"
                  ? "selectedTab"
                  : "notSelectedTab"
              }
            >
              {t("lang.Activity")}
            </div>
            <div
              onClick={() => handleRightTabClick("Chatter")}
              className={
                RightSelectedTab === "Chatter"
                  ? "selectedTab"
                  : "notSelectedTab"
              }
            >
              {t("lang.Chatter")}
            </div>
          </div>

          {RightSelectedTab === "Activity" && (
            <div className="ActivityBox">
              <div className="ActivityInside">
                {/* <div className="ActivityInsideBox"> */}
                <Chip
                  label={t("lang.LogCall")}
                  variant="outlined"
                  onClick={()=>setChipSelected(t("lang.LogCall"))}
                  className={isChipSelected===t("lang.LogCall") ? "selectedChip" : "ChipBtn"}
                />
                <Chip
                  label={t("lang.EmailSmall")}
                  variant="outlined"
                  onClick={()=>setChipSelected(t("lang.Email"))}
                  className={isChipSelected===t("lang.Email") ? "selectedChip" : "ChipBtn"}
                />
                <Chip
                  label={t("lang.NewTask")}
                  variant="outlined"
                  onClick={()=>setChipSelected(t("lang.NewTask"))}
                  className={isChipSelected===t("lang.NewTask") ? "selectedChip" : "ChipBtn"}
                />
                <Chip
                  label={t("lang.NewEvent")}
                  variant="outlined"
                  onClick={()=>setChipSelected(t("lang.NewEvent"))}
                  className={isChipSelected===t("lang.NewEvent") ? "selectedChip" : "ChipBtn"}
                />
                {/* </div> */}
              </div>

              <div className="InputBox">
                <TextField
                  name=""
                  placeholder="Recap Your Call"
                  InputProps={{
                    className: "inputFieldText",
                  }}
                />

                <NextButton>{t("lang.Add")}</NextButton>
              </div>
            </div>
          )}
        </div>
      </div>

      <LeadEdit
        isDetailUpdate={isDetailUpdate}
        setIsDetailUpdate={setIsDetailUpdate}
        leadData={leadDetail?.lead}
      />

      <LeadStatusModal
        lead={id}
        open={open}
        onClose={() => setOpen(false)}
        status={status}
      />
    </div>
  );
};

export default LeadDetails;
