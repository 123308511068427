import { Button } from "@mui/material";
import styles from "./OutlineButton.module.scss";
import React, { ButtonHTMLAttributes } from "react";

interface OutlineButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  color?: "white";
}

export const OutlineButton = ({
  children,
  color,
  style,
  ...rest
}: OutlineButtonProps) => {
  return (
    <Button
      // color='white'
      sx={{
        borderRadius: "31px",
        fontWeight: 600,
        border: "2px solid #2659ed",
        padding: "9.2px 40px",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        transition: "all 0.3s ease",
        backgroundColor: "transparent",
        verticalAlign: "middle",
        color: "primary.main",
        "&:hover": {
          // color: '#fff',
          backgroundColor: "rgba(38, 89, 237, 0.10)",
        },
        "&:disabled": {
          border: "2px solid #6F6F6F",
          backgroundColor: "transparent",
        },
        ...style,
      }}
      className={styles.button}
      variant="contained"
      {...rest}
    >
      {children}
    </Button>
  );
};
