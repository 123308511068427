import React, { useEffect, useState } from "react";

import LeadTable from "./LeadTable";
import { Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../Redux/Store";
import CreateLead from "./CreateLead/CreateLead";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, MenuItem, Select } from "@mui/material";
import { allLeads } from "../../../Redux/Features/Lead-Slice";
import { OutlineTextField } from "../../../Helper/input/OutlineTextField";
import "./Leads.scss";

const selectSx = {
  "& .MuiInputBase-input": {
    padding: "10px 24px",
    minWidth: " 150px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "30px",
    border: "2px solid #404b7c38",
  },
};
interface LeadProps {
  isCreate: boolean;
  setIsCreate: Function;
}

const Leads = (props: LeadProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { isCreate, setIsCreate } = props;
  const [search, setSearch] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const pageSize = 5;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const options = [
    t("lang.All"),
    t("lang.New"),
    t("lang.Contracted"),
    t("lang.Working"),
    t("lang.Unqualified"),
    t("lang.Converted"),
    t("lang.Completed"),
  ];

  const leadsData = useSelector((state: RootState) => state?.leads);

  useEffect(() => {
    dispatch(allLeads({ page: currentPage, limit: pageSize }));
  }, [currentPage]);

  const onCategoryPaginationChange = async (page: number) => {
    await dispatch(allLeads({ page: page, limit: pageSize }));
    setCurrentPage(page);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value as string);
  };

  return (
    <div className="leadstable">
      <CreateLead isCreate={isCreate} setIsCreate={setIsCreate} />
      <div className="leadstableHeader">
        <OutlineTextField
          className="CustomsearchBox"
          name="email"
          value={search}
          onChange={handleChange}
          placeholder={t("lang.Search")}
          InputProps={{
            startAdornment: (
              <IconButton sx={{ color: "black" }}>
                <Search />
              </IconButton>
            ),
          }}
        />
        <Select
          className="CustomSelect"
          defaultValue=""
          sx={selectSx}
          name="position"
          displayEmpty
          onChange={handleCategoryChange}
        >
          <MenuItem disabled value="">
            {t("lang.SelectAllOption")}
          </MenuItem>
          {options?.map((option) => (
            <MenuItem key={option} value={option?.toLowerCase()}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
      <LeadTable
        leadsData={leadsData}
        page={currentPage}
        search={search}
        selectedCategory={selectedCategory}
        onPaginationChange={onCategoryPaginationChange}
      />
    </div>
  );
};

export default Leads;
