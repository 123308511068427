import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = () => {
  const arrayString = localStorage.getItem("bearerToken");

  let userExist: any = {};
  userExist = arrayString && arrayString !== "undefined" ? arrayString : "";

  return userExist ? <Outlet /> : <Navigate to="/signin" />;
};

export default PrivateRoute;
