class GlobalAppUrls {
  public PrivateURL = {
    Dashboard: "/",
    AllLeads: "/leads",
    LeadDetails: "/leads/details/:id",
  };

  public PublicURL = {
    SignIn: "/signin",
    ForgetPassword: "/forgot-password/request-otp",
  };
}

export const AppUrls = new GlobalAppUrls();
