import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { EmailOutlined, Menu } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import AccountPopover from "../Account-Popover/Account-Popover";
import notificationIcon from "../../Assets/Images/PNG/notificationIcon.png";
import { NextButton } from "../../Helper/button/NextButton/NextButton";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { userProfile } from "../../Redux/Features/Auth-Slice";
import { RootState } from "../../Redux/Store";
import { useTranslation } from "react-i18next";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import "./Header.scss";

type Anchor =  "left";
interface SingleTabProps {
  label: string;
  Icon: any;
  selected: string;
  path: string;
  setSelected: (e: string) => void;
}

interface ChildProps {
  menuEvent: () => void;
  openMenu: boolean;
  showLogout: boolean;
  setIsCreate?: any;
  // leadData?: any;
  setOpenSideBar?: Function;
}

const Header: React.FC<ChildProps> = (props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const email = localStorage.getItem("email");
  const role = localStorage.getItem("role");

  useEffect(() => {
    dispatch(userProfile({ email: email }));
  }, [email]);

  const location = useLocation();
  const Profile: any = useSelector((state: RootState) => state?.leads);
  const UserProfile = useSelector((state: RootState) => state?.user);

  const { t } = useTranslation();

  const TextSkeleton = () => (
    <Skeleton variant="text" sx={{ minWidth: "12rem" }} />
  );

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      // sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box className="mainHeader">
      <Box
        // onClick={() => props.menuEvent()}
        className="mainHeader__heading"
      >
        <Box className="mainHeader__heading-details">
          {Profile && location.pathname.startsWith("/leads/details") ? (
            <Typography variant="h6" sx={{ color: "#1976D2" }}>
              {Profile?.isFetching ? (
                <TextSkeleton />
              ) : (
                Profile?.lead?.firstName + " " + Profile?.lead?.lastName
              )}
            </Typography>
          ) : (
            <>
              {location.pathname === "/" && (
                <Typography variant="h6" sx={{ color: "#1976D2" }}>
                  {t("lang.Dashboard")}
                </Typography>
              )}
              {location.pathname === "/leads" && (
                <>
                  <Typography variant="h6" sx={{ color: "#1976D2" }}>
                    {t("lang.AllLeads")}
                  </Typography>
                  {role !== "ADMINISTRATOR" ? (
                    ""
                  ) : (
                    <NextButton onClick={() => props.setIsCreate(true)}>
                      {t("lang.NewLead")}
                    </NextButton>
                  )}
                </>
              )}
            </>
          )}

          {/* <DensityMediumIcon className="menuIcon" onClick={()=>props?.setOpenSideBar?.(true)}/> */}

          {/* {(["left"] as const).map((anchor) => (
            <React.Fragment key={anchor}>
              <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))} */}
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: {
              xs: "none",
              md: "flex",
              alignItems: "center",
              gap: "16px",
            },
          }}
        >
          <IconButton
            className="mainHeader__notificationIcon"
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            {/* <Badge badgeContent={4} color="error"> */}
            {/* <EmailOutlined sx={{ color: '#000' }} /> */}
            <img src={notificationIcon} />
            {/* </Badge> */}
          </IconButton>

          <Typography>{UserProfile?.profile?.name}</Typography>
          <AccountPopover profile={UserProfile?.profile} />
        </Box>
      </Box>
    </Box>
  );
};
export default Header;
