import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  ErrorMessage,
  Form,
  FormikHandlers,
  FormikHelpers,
  FormikState,
} from "formik";
import { validationError } from "../../../Constraints/Shared.Validation";
import { NextButton } from "../../../Helper/button/NextButton/NextButton";
import { OutlineButton } from "../../../Helper/button/OutlineButton/OutlineButton";
import Arrow_Undo_Up_Left from "../../../Assets/Images/SVG/Arrow_Undo_Up_Left.svg";
import attachment from "../../../Assets/Images/SVG/Attachment.svg";
import "./Leads.scss";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/Store";
import { clearState } from "../../../Redux/Features/Lead-Slice";

export const MuiStyle = {
  "& .MuiInputBase-input": { padding: "16px 24px" },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "30px",
    border: "1px solid #D9E4FF",
  },
};

const selectSx = {
  "& .MuiInputBase-input": {
    padding: "10px 24px", // add your desired padding values
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "30px", // add the desired border radius
    border: "2px solid #404b7c38",
  },
};

const ratings = ["AAA", "AA", "A", "BBB", "BB", "B", "CCC", "CC", "C", "D"];

const leadSource = ["Yes", "No"];

export const industries = [
  "Accountants",
  "AdvertisingPublicRelations",
  "AerospaceDefenseContractors",
  "Agribusiness",
  "AgriculturalServicesProducts",
  "Agriculture",
  "AirTransport",
  "AirTransportUnions",
  "Airlines",
  "AlcoholicBeverages",
  "AlternativeEnergyProductionServices",
  "ArchitecturalServices",
  "AttorneysLawFirms",
  "AutoDealers",
  "AutoDealersForeignImports",
  "AutoManufacturers",
  "Automotive",
  "BankingMortgage",
  "BanksCommercial",
  "BanksSavingsLoans",
  "BarsRestaurants",
  "BeerWineLiquor",
  "BooksMagazinesNewspapers",
  "BroadcastersRadioTV",
  "BuildersGeneralContractors",
  "BuildersResidential",
  "BuildingMaterialsEquipment",
  "BuildingTradeUnions",
  "BusinessAssociations",
  "BusinessServices",
  "CableSatelliteTVProductionDistribution",
  "CandidateCommittees",
  "CandidateCommitteesDemocratic",
  "CandidateCommitteesRepublican",
  "CarDealers",
  "CarDealersImports",
  "CarManufacturers",
  "CasinosGambling",
  "CattleRanchersLivestock",
  "ChemicalRelatedManufacturing",
  "Chiropractors",
  "CivilServantsPublicOfficials",
  "ClergyReligiousOrganizations",
  "ClothingManufacturing",
  "CoalMining",
  "CollegesUniversitiesSchools",
  "CommercialBanks",
  "CommercialTVRadioStations",
  "CommunicationsElectronics",
  "ComputerSoftware",
  "ConservativeRepublican",
  "Construction",
  "ConstructionServices",
  "ConstructionUnions",
  "CreditUnions",
  "CropProductionBasicProcessing",
  "CruiseLines",
  "CruiseShipsLines",
  "Dairy",
  "Defense",
  "DefenseAerospace",
  "DefenseElectronics",
  "DefenseForeignPolicyAdvocates",
  "DemocraticCandidateCommittees",
  "DemocraticLeadershipPACs",
  "DemocraticLiberal",
  "Dentists",
  "DoctorsOtherHealthProfessionals",
  "DrugManufacturers",
  "Education",
  "ElectricUtilities",
  "ElectronicsManufacturingEquipment",
  "ElectronicsDefenseContractors",
  "EnergyNaturalResources",
  "EntertainmentIndustry",
  "Environment",
  "FarmBureaus",
  "Farming",
  "FinanceCreditCompanies",
  "FinanceInsuranceRealEstate",
  "FoodBeverage",
  "FoodProcessingSales",
  "FoodProductsManufacturing",
  "FoodStores",
  "ForprofitEducation",
  "ForprofitPrisons",
  "ForeignDefensePolicy",
  "ForestryForestProducts",
  "FoundationsPhilanthropistsNonProfits",
  "FuneralServices",
  "GamblingCasinos",
  "GamblingIndianCasinos",
  "GarbageCollectionWasteManagement",
  "GasOil",
  "GeneralContractors",
  "GovernmentEmployeeUnions",
  "GovernmentnoOfEmployee",
  "GunControl",
  "GunRights",
  "Health",
  "HealthProfessionals",
  "HealthServicesHMOs",
  "HedgeFunds",
  "HMOsHealthCareServices",
  "HomeBuilders",
  "HospitalsNursingHomes",
  "HotelsMotelsTourism",
  "HumanRights",
  "IdeologicalSingleIssue",
  "IndianGaming",
  "IndustrialUnions",
  "Insurance",
  "Internet",
  "IsraelPolicy",
  "Labor",
  "LawyersLobbyists",
  "LawyersLawFirms",
  "LeadershipPACs",
  "LGBTQIARightsIssues",
  "LiberalDemocratic",
  "LiquorWineBeer",
  "Livestock",
  "Lobbyists",
  "LodgingTourism",
  "LoggingTimberPaperMills",
  "ManufacturingMisc",
  "Marijuana",
  "MarineTransport",
  "MeatProcessingProducts",
  "MedicalSupplies",
  "Mining",
  "MiscBusiness",
  "MiscFinance",
  "MiscManufacturingDistributing",
  "MiscUnions",
  "MiscellaneousDefense",
  "MiscellaneousServices",
  "MortgageBankersBrokers",
  "MotionPictureProductionDistribution",
  "MusicProduction",
  "NaturalGasPipelines",
  "NewspaperMagazineBookPublishing",
  "NonprofitsFoundationsPhilanthropists",
  "Nurses",
  "NursingHomesHospitals",
  "NutritionalDietarySupplements",
  "OilGas",
  "Other",
  "PaydayLenders",
  "PharmaceuticalManufacturing",
  "PharmaceuticalsHealthProducts",
  "PhoneCompanies",
  "PhysiciansOtherHealthProfessionals",
  "PostalUnions",
  "PoultryEggs",
  "PowerUtilities",
  "PrintingPublishing",
  "PrivateEquityInvestmentFirms",
  "ProIsrael",
  "ProfessionalSportsSportsArenasRelatedEquipmentServices",
  "ProgressiveDemocratic",
  "PublicEmployees",
  "PublicSectorUnions",
  "PublishingPrinting",
  "RadioTVStations",
  "Railroads",
  "RealEstate",
  "RecordCompaniesSingers",
  "RecordedMusicMusicProduction",
  "RecreationLiveEntertainment",
  "ReligiousOrganizationsClergy",
  "RepublicanCandidateCommittees",
  "RepublicanLeadershipPACs",
  "RepublicanConservative",
  "ResidentialConstruction",
  "RestaurantsDrinkingEstablishments",
  "RetailSales",
  "Retired",
  "SavingsLoans",
  "SchoolsEducation",
  "SeaTransport",
  "SecuritiesInvestment",
  "SpecialTradeContractors",
  "SportsProfessional",
  "SteelProduction",
  "StockBrokersInvestmentIndustry",
  "StudentLoanCompanies",
  "SugarCaneSugarBeets",
  "TeachersUnions",
  "TeachersEducation",
  "TelecomServicesEquipment",
  "TelephoneUtilities",
  "Textiles",
  "TimberLoggingPaperMills",
  "Tobacco",
  "Transportation",
  "TransportationUnions",
  "TrashCollectionWasteManagement",
  "Trucking",
  "TVMoviesMusic",
  "TVProduction",
  "Unions",
  "UnionsAirline",
  "UnionsBuildingTrades",
  "UnionsIndustrial",
  "UnionsMisc",
  "UnionsPublicSector",
  "UnionsTeacher",
  "UnionsTransportation",
  "UniversitiesCollegesSchools",
  "VegetablesFruits",
  "VentureCapital",
  "WasteManagement",
  "WineBeerLiquor",
];

export interface LeadCreateProps {
  firstName: string;
  leadStatus: string;
  lastName: string;
  phone: string;
  company: string;
  email: string;
  title: string;
  rating: string;
  followUp: boolean;
  street: string;
  website: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  noOfEmployee: string;
  leadSource: string;
  annualRevenue: string;
  industry: string;
  description: string;
  document: string;
}

interface FaqCreateFormProps {
  leadDetail?: any;
  setSaveOrSaveAndNew?: any;
  isDetailUpdate?: boolean;
  id?: string;
  setIsDetailUpdate?: any;
  isCreate?: boolean;
  setIsCreate?: Function;
  isUpdate?: boolean;
  setIsUpdate?: Function;
  onClose?: Function;
}

const LeadDialog = (
  props: Pick<FormikHandlers, "handleChange" | "handleBlur"> &
    Pick<FormikHelpers<any>, "setFieldValue"> &
    Pick<FormikState<LeadCreateProps>, "errors" | "values" | "touched"> &
    FaqCreateFormProps
) => {
  const {
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    isCreate,
    setIsCreate,
    isUpdate,
    setIsUpdate,
    onClose,
    setFieldValue,
    isDetailUpdate,
    setIsDetailUpdate,
  } = props;
  const handleCloseButton = () => {
    setIsCreate?.(false);
    onClose?.();
    setIsUpdate?.(false);
    setIsDetailUpdate?.(false);
  };
  const { t } = useTranslation();
  const options = [
    t("lang.New"),
    t("lang.Contracted"),
    t("lang.Working"),
    t("lang.Unqualified"),
    t("lang.Converted"),
  ];
  const dispatch = useDispatch();

  const [fileUpload, setFileUpload] = useState<any>();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [isOpen, setisOpen] = useState<any>(false);
  const [formValue, setFormValue] = useState<any>({});
  const handleFileUpload = (event: any) => {
    setFileUpload({ value: event.target.files[0], field: "uploadFile" });
    setFieldValue("document", event.target.files[0]);
  };

  const leadDetail = useSelector((state: RootState) => state?.leads);

  console.log(props?.leadDetail?.followUp, "props?.leadDetail?.followUp");
  useEffect(() => {
    if (isCreate || isUpdate || isDetailUpdate) {
      setisOpen(true);
    } else {
      setisOpen(false);
    }
  }, [isCreate, isUpdate, isDetailUpdate]);

  useEffect(() => {
    if (props?.leadDetail) {
      // setFormValue({
      //   // ...other fields
      //   document: props?.leadDetail?.document || null,
      // });
      if (props?.leadDetail?.document) {
        setPdfUrl(props?.leadDetail?.document);
      }
    }
  }, [props?.leadDetail]);

  useEffect(() => {
    if (props?.leadDetail) {
      setFormValue({
        firstName: props?.leadDetail?.firstName || "",
        leadStatus: props?.leadDetail?.leadStatus || "",
        lastName: props?.leadDetail?.lastName || "",
        phone: props?.leadDetail?.phone || "",
        company: props?.leadDetail?.company || "",
        email: props?.leadDetail?.email || "",
        title: props?.leadDetail?.title || "",
        rating: props?.leadDetail?.rating || "",
        followUp: props?.leadDetail?.followUp || false,
        street: props?.leadDetail?.street || "",
        website: props?.leadDetail?.website || "",
        city: props?.leadDetail?.city || "",
        state: props?.leadDetail?.state || "",
        zipCode: props?.leadDetail?.zipCode || "",
        country: props?.leadDetail?.country || "",
        noOfEmployee: props?.leadDetail?.noOfEmployee || "",
        leadSource: props?.leadDetail?.leadSource || "",
        annualRevenue: props?.leadDetail?.annualRevenue || "",
        industry: props?.leadDetail?.industry || "",
        description: props?.leadDetail?.description || "",
        // document: props?.leadDetail?.document || '',
      });
    }
  }, [props?.leadDetail]);

  useEffect(() => {
    if (formValue?.firstName || formValue?.firstName === "") {
      setFieldValue("firstName", formValue?.firstName);
    }
    if (formValue?.leadStatus || formValue?.leadStatus === "") {
      setFieldValue("leadStatus", formValue?.leadStatus);
    }
    if (formValue?.lastName || formValue?.lastName === "") {
      setFieldValue("lastName", formValue?.lastName);
    }
    if (formValue?.phone || formValue?.phone === "") {
      setFieldValue("phone", formValue?.phone);
    }
    if (formValue?.company || formValue?.company === "") {
      setFieldValue("company", formValue?.company);
    }
    if (formValue?.email || formValue?.email === "") {
      setFieldValue("email", formValue?.email);
    }
    if (formValue?.title || formValue?.title === "") {
      setFieldValue("title", formValue?.title);
    }
    if (formValue?.rating || formValue?.rating === "") {
      setFieldValue("rating", formValue?.rating);
    }
    if (formValue?.followUp || formValue?.followUp === false) {
      setFieldValue("followUp", formValue?.followUp);
    }
    if (formValue?.street || formValue?.street === "") {
      setFieldValue("street", formValue?.street);
    }
    if (formValue?.website || formValue?.website === "") {
      setFieldValue("website", formValue?.website);
    }
    if (formValue?.city || formValue?.city === "") {
      setFieldValue("city", formValue?.city);
    }
    if (formValue?.state || formValue?.state === "") {
      setFieldValue("state", formValue?.state);
    }
    if (formValue?.zipCode || formValue?.zipCode === "") {
      setFieldValue("zipCode", formValue?.zipCode);
    }
    if (formValue?.country || formValue?.country === "") {
      setFieldValue("country", formValue?.country);
    }
    if (formValue?.noOfEmployee || formValue?.noOfEmployee === "") {
      setFieldValue("noOfEmployee", formValue?.noOfEmployee);
    }
    if (formValue?.leadSource || formValue?.leadSource === "") {
      setFieldValue("leadSource", formValue?.leadSource);
    }
    if (formValue?.annualRevenue || formValue?.annualRevenue === "") {
      setFieldValue("annualRevenue", formValue?.annualRevenue);
    }
    if (formValue?.industry || formValue?.industry === "") {
      setFieldValue("industry", formValue?.industry);
    }
    if (formValue?.description || formValue?.description === "") {
      setFieldValue("description", formValue?.description);
    }
    // if (formValue?.document || formValue?.document === '') {
    //   setFieldValue('document', formValue?.document);
    // }
  }, [formValue]);

  return (
    <Dialog
      open={isOpen}
      className="LeadDialog"
      PaperProps={{
        style: {
          width: "100%",
          backgroundColor: "#F3F6FD",
          maxWidth: "740px",
          borderRadius: "48px",
          padding: "0px",
        },
      }}
    >
      <DialogTitle className="LeadDialog__Title">
        {t("lang.NewLead")}
        <IconButton
          aria-label="close"
          onClick={handleCloseButton}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Form>
        <div className="FormInnerBox">
          <div className="LeadDialog__innerTitle">LeadInformation</div>
          <label>*{t("lang.Name")}</label>
          <div className="LeadDialog__BoxDetails">
            <div className="FormGroup">
              <label className="FormLabel">*{t("lang.FirstName")}</label>
              <TextField
                className="FormControl"
                name="firstName"
                style={{ width: "100%" }}
                placeholder="FirstName"
                InputProps={{
                  className: "inputText",
                }}
                error={Boolean(touched.firstName) && Boolean(errors.firstName)}
                helperText={validationError(
                  errors.firstName,
                  touched.firstName
                )}
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="FormGroup">
              <label className="FormLabel">{t("lang.LeadStatus")}</label>
              <Select
                className="FormSelect"
                sx={selectSx}
                name="leadStatus"
                style={{ width: "100%" }}
                placeholder="LeadStatus"
                error={
                  Boolean(touched.leadStatus) && Boolean(errors.leadStatus)
                }
                value={values.leadStatus}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {options?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <ErrorMessage
                name="leadStatus"
                className="errorMsg"
                component="div"
              />
            </div>
            <div className="FormGroup">
              <label className="FormLabel">*{t("lang.LastName")}</label>
              <TextField
                className="FormControl"
                name="lastName"
                style={{ width: "100%" }}
                placeholder="LastName"
                InputProps={{
                  className: "inputText",
                }}
                error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                helperText={validationError(errors.lastName, touched.lastName)}
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="FormGroup">
              <label className="FormLabel">{t("lang.Phone")}</label>
              <TextField
                className="FormControl"
                name="phone"
                InputProps={{
                  className: "inputText",
                }}
                error={Boolean(touched.phone) && Boolean(errors.phone)}
                helperText={validationError(errors.phone, touched.phone)}
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="FormGroup">
              <label className="FormLabel">*{t("lang.Company")}</label>
              <TextField
                className="FormControl"
                name="company"
                InputProps={{
                  className: "inputText",
                }}
                error={Boolean(touched.company) && Boolean(errors.company)}
                helperText={validationError(errors.company, touched.company)}
                value={values.company}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="FormGroup">
              <label className="FormLabel">{t("lang.Email")}</label>
              <TextField
                className="FormControl"
                name="email"
                InputProps={{
                  className: "inputText",
                }}
                error={Boolean(touched.email) && Boolean(errors.email)}
                helperText={validationError(errors.email, touched.email)}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="FormGroup">
              <label className="FormLabel">{t("lang.Title")}</label>
              <TextField
                className="FormControl"
                name="title"
                placeholder=""
                InputProps={{
                  className: "inputText",
                }}
                error={Boolean(touched.title) && Boolean(errors.title)}
                helperText={validationError(errors.title, touched.title)}
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="FormGroup">
              <label className="FormLabel">{t("lang.Rating")}</label>
              <Select
                className="FormSelect"
                name="rating"
                defaultValue=""
                sx={selectSx}
                placeholder="--None--"
                onBlur={handleBlur}
                value={values.rating}
                error={Boolean(touched.rating) && Boolean(errors.rating)}
                onChange={handleChange}
              >
                {ratings?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <ErrorMessage
                name="rating"
                className="errorMsg"
                component="div"
              />
            </div>
            <div className="FormGroup">
              <label className="FormLabel">{t("lang.FollowUp")}?</label>
              <FormControlLabel
                className="FormCheckBox"
                name="followUp"
                control={
                  <Checkbox
                    sx={{ marginTop: "-20px", padding: "0px" }}
                    checked={values.followUp}
                    onChange={handleChange}
                  />
                }
                label={""}
              />
              <span
                style={{ color: "red", fontWeight: "300", fontSize: "12px" }}
              >
                {validationError(errors.followUp, touched.followUp)}
              </span>
            </div>
          </div>

          <div className="LeadDialog__innerTitle">
            {t("lang.AddressInformation")}
          </div>
          <label>*{t("lang.AddressInformation")}</label>

          <div className="LeadDialog__BoxDetails">
            <div className="FormGroup">
              <label className="FormLabel">*{t("lang.Street")}</label>
              <TextareaAutosize
                className={`FormControl ${
                  Boolean(touched.street) && Boolean(errors.street)
                    ? "error"
                    : ""
                }`}
                aria-label="street"
                name="street"
                value={values.street}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{
                  height: "80px",
                  width: "100%",
                  borderColor:
                    Boolean(touched.street) && Boolean(errors.street)
                      ? "red"
                      : "initial",
                }}
              />
              <p className="errorMsg">
                {validationError(errors.street, touched.street)}
              </p>
            </div>

            <div className="FormGroup">
              <label className="FormLabel">{t("lang.Website")}</label>
              <TextField
                className="FormControl"
                name="website"
                InputProps={{
                  className: "inputText",
                }}
                error={Boolean(touched.website) && Boolean(errors.website)}
                helperText={validationError(errors.website, touched.website)}
                value={values.website}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <div className="FormGroup">
                <label className="FormLabel">{t("lang.City")}</label>
                <TextField
                  className="FormControl"
                  name="city"
                  helperText={validationError(errors.city, touched.city)}
                  error={Boolean(touched.city) && Boolean(errors.city)}
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    className: "inputText",
                  }}
                />
              </div>
              <div className="FormGroup">
                <label className="FormLabel">{t("lang.StateProvince")}</label>
                <TextField
                  className="FormControl"
                  name="state"
                  InputProps={{
                    className: "inputText",
                  }}
                  error={Boolean(touched.state) && Boolean(errors.state)}
                  helperText={validationError(errors.state, touched.state)}
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div></div>

            <div style={{ display: "flex", gap: "10px" }}>
              <div className="FormGroup">
                <label className="FormLabel">{t("lang.ZipPostalCode")}</label>
                <TextField
                  className="FormControl"
                  name="zipCode"
                  InputProps={{
                    className: "inputText",
                  }}
                  error={Boolean(touched.zipCode) && Boolean(errors.zipCode)}
                  helperText={validationError(errors.zipCode, touched.zipCode)}
                  value={values.zipCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="FormGroup">
                <label className="FormLabel">{t("lang.Country")}</label>
                <TextField
                  className="FormControl"
                  name="country"
                  InputProps={{
                    className: "inputText",
                  }}
                  error={Boolean(touched.country) && Boolean(errors.country)}
                  helperText={validationError(errors.country, touched.country)}
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>

          <div className="LeadDialog__innerTitle">
            {t("lang.AdditionalInformation")}
          </div>
          <div className="LeadDialog__BoxDetails">
            <div className="FormGroup">
              <label className="FormLabel">{t("lang.NoOfEmployee")}</label>
              <TextField
                className="FormControl"
                name="noOfEmployee"
                InputProps={{
                  className: "inputText",
                }}
                error={
                  Boolean(touched.noOfEmployee) && Boolean(errors.noOfEmployee)
                }
                helperText={validationError(
                  errors.noOfEmployee,
                  touched.noOfEmployee
                )}
                value={values.noOfEmployee}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div
              className="FormGroup"
              style={{
                background: "#FEFFB9",
                padding: "2px 5px 5px 5px",
                borderRadius: "10px",
              }}
            >
              <label
                className="FormLabel"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {t("lang.LeadSource")} <img src={Arrow_Undo_Up_Left} alt="" />
              </label>
              <Select
                className="FormSelect"
                name="leadSource"
                sx={selectSx}
                displayEmpty
                style={{ width: "100%" }}
                value={values.leadSource}
                error={touched.leadSource && Boolean(errors.leadSource)}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {leadSource?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <ErrorMessage
                name="leadSource"
                className="errorMsg"
                component="div"
              />
            </div>
            <div className="FormGroup">
              <label className="FormLabel">{t("lang.AnnualRevenue")}</label>
              <TextField
                className="FormControl"
                name="annualRevenue"
                placeholder=""
                InputProps={{
                  className: "inputText",
                }}
                error={
                  Boolean(touched.annualRevenue) &&
                  Boolean(errors.annualRevenue)
                }
                helperText={validationError(
                  errors.annualRevenue,
                  touched.annualRevenue
                )}
                value={values.annualRevenue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div className="FormGroup">
              <label className="FormLabel">{t("lang.Industry")}</label>
              <Select
                className="FormSelect"
                name="industry"
                error={touched.industry && Boolean(errors.industry)}
                value={values.industry}
                sx={selectSx}
                displayEmpty
                style={{ width: "100%" }}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {industries?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <ErrorMessage
                name="industry"
                className="errorMsg"
                component="div"
              />
            </div>
          </div>

          <div className="LeadDialog__innerTitle">
            {t("lang.AddressInformation")}
          </div>
          <div className="FormGroup">
            <label className="FormLabel">{t("lang.Description")}</label>
            <TextareaAutosize
              className={`FormControl ${
                Boolean(touched.description) && Boolean(errors.description)
                  ? "error"
                  : ""
              }`}
              placeholder="Write Description"
              aria-label="description"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{
                height: "120px",
                width: "100%",
                borderColor:
                  Boolean(touched.description) && Boolean(errors.description)
                    ? "red"
                    : "initial",
              }}
            />
            <p className="errorMsg">
              {validationError(errors.description, touched.description)}
            </p>
          </div>

          <Box className="fileCustomField">
            <TextField
              type="file"
              label={""}
              onChange={handleFileUpload}
              onBlur={handleBlur}
            />
            <div className="fileCustomBtn">
              {fileUpload ? (
                <>
                  <img src={attachment} alt="attachment" />
                  <span>{fileUpload?.value?.name}</span>
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M8 12H12M12 12H16M12 12V16M12 12V8"
                      stroke="#2659ED"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="9"
                      stroke="#2659ED"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {t("lang.UploadDoc")}
                </>
              )}
            </div>
          </Box>
          {!fileUpload && pdfUrl && (
            <div
              style={{
                width: "300px",
                height: "80px",
                border: "1px solid black",
                position: "relative",
                // overflow: 'hidden',
                marginTop: "20px",
              }}
            >
              <iframe
                src={pdfUrl}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  zIndex: 1,
                }}
              ></iframe>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  zIndex: 2,
                }}
                onClick={() => window.open(pdfUrl, "_blank")}
              ></div>
            </div>
          )}
        </div>
        {leadDetail?.error ? (
          <Box className="containerSignin__errorContainer">
            <Box className="error">
              <Box className="errorDetails">
                <Box className="errorText">
                  <Typography>{leadDetail?.error}</Typography>
                </Box>
                <CloseIcon
                  onClick={() => dispatch(clearState())}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          ""
        )}

        <div className="LeadDialog__DialogButton">
          <NextButton
            type="submit"
            onClick={() =>
              isUpdate || isDetailUpdate
                ? ""
                : props?.setSaveOrSaveAndNew("Save")
            }
            disabled={
              leadDetail?.isFetching ||
              JSON.stringify(formValue) === JSON.stringify(values)
            }
            loading={leadDetail?.isFetching}
          >
            {t("lang.Save")}
          </NextButton>
          {isUpdate || isDetailUpdate ? (
            ""
          ) : (
            <OutlineButton
              style={{ margin: 0, fontWeight: 600 }}
              onClick={() => props?.setSaveOrSaveAndNew("SaveAndNew")}
              type="submit"
              disabled={leadDetail?.isFetching}
            >
              {t("lang.SaveAndNew")}
              {leadDetail?.isFetching ? (
                <CircularProgress
                  sx={{ color: "#FFF", marginLeft: "8px" }}
                  size={16}
                />
              ) : (
                ""
              )}
            </OutlineButton>
          )}
          <OutlineButton
            style={{ margin: 0, fontWeight: 600 }}
            onClick={handleCloseButton}
          >
            {t("lang.Cancel")}
          </OutlineButton>
        </div>
      </Form>
    </Dialog>
  );
};

export default LeadDialog;
