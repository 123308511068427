import React, { useMemo, useState } from "react";
import {
  IconButton,
  MenuItem,
  Pagination,
  Popover,
  Stack,
  TableBody,
  TableCell,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
} from "@mui/material";
import LeadEdit from "./LeadEdit/LeadEdit";
import { MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LeadDeleteModal from "./LeadsDeleteModal";
import { TableLoadingSkeleton } from "../../../Component/TableLoader/Table.Loading";

import "./Leads.scss";

interface LeadsTableProps {
  leadsData: any;
  page: number;
  search: string;
  selectedCategory?: string;
  onPaginationChange: (p: number) => void;
}

const LeadTable = (props: LeadsTableProps) => {
  const { leadsData, page, search, selectedCategory, onPaginationChange } =
    props;
  const [leadData, setLeadData] = useState<any>({});
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [selectedLead, setSelectedLead] = useState<any>();
  const [selectedLeadDetail, setSelectedLeadDetail] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const role = localStorage.getItem("role");
  const filteredLeads = useMemo(() => {
    let filteredList = leadsData?.leads || [];

    // Filter based on the selected category
    if (selectedCategory && selectedCategory.toLowerCase() !== "all") {
      filteredList = filteredList.filter(
        (lead: any) =>
          lead?.leadStatus?.toLowerCase() === selectedCategory.toLowerCase()
      );
    }

    // Filter based on the search term
    if (search) {
      filteredList = filteredList.filter((lead: any) =>
        Object.values(lead).some((value: any) =>
          value?.toString()?.toLowerCase()?.includes(search.toLowerCase())
        )
      );
    }

    return filteredList;
  }, [search, leadsData?.leads, selectedCategory]);

  const handleDelete = () => {
    setSelectedLead(leadData?._id);
    setOpen(null);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <div className="LeadTable">
        <TableContainer component={Paper}>
          <Table sx={{ width: "100%" }} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell> {t("lang.Name")}</TableCell>
                <TableCell align="right">{t("lang.Company")}</TableCell>
                <TableCell align="right">{t("lang.Country")}</TableCell>
                <TableCell align="right">{t("lang.Email")}</TableCell>
                <TableCell align="right">{t("lang.LeadStatus")}</TableCell>
                <TableCell align="right">{t("lang.DateCreated")}</TableCell>
                {role !== "ADMINISTRATOR" ? (
                  ""
                ) : (
                  <TableCell align="right">{t("lang.Action")}</TableCell>
                )}
              </TableRow>
            </TableHead>
            {leadsData?.isFetching ? (
              <TableLoadingSkeleton colSpan={7} rowsPerPage={4} />
            ) : (
              <TableBody>
                {filteredLeads?.length > 0 ? (
                  filteredLeads?.map((row: any, index: number) => (
                    <TableRow
                      key={index}
                      onClick={(item: any) => {
                        navigate(`/leads/details/${row?._id}`);
                      }}
                    >
                      <TableCell style={{ fontWeight: "600" }}>
                        {row?.firstName}
                      </TableCell>
                      <TableCell align="right">{row?.company}</TableCell>
                      <TableCell align="right">{row?.country}</TableCell>
                      <TableCell align="right">{row?.email}</TableCell>
                      <TableCell align="right">{row?.leadStatus}</TableCell>
                      <TableCell align="right">
                        {new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }).format(new Date(row?.createdAt))}
                      </TableCell>
                      {role !== "ADMINISTRATOR" ? (
                        ""
                      ) : (
                        <TableCell align="right">
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              setOpen(event?.currentTarget);
                              setLeadData(row);
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center" sx={{ padding: 1 }}>
                      <em className="noData">No Data</em>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Stack spacing={2} className="Tablepagination">
          <Pagination
            onChange={(_, p) => onPaginationChange(p)}
            page={page}
            count={leadsData?.totalPages}
          />
        </Stack>
      </div>
      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        <MenuItem
          onClick={() => {
            setIsUpdate(true);
            setSelectedLeadDetail(leadData);
            setOpen(null);
          }}
        >
          {t("lang.Edit")}
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={{ color: "error.main" }}>
          {t("lang.Delete")}
        </MenuItem>
      </Popover>

      <LeadEdit
        isUpdate={isUpdate}
        leadData={selectedLeadDetail}
        setIsUpdate={setIsUpdate}
        onClose={() => {
          setSelectedLead(undefined);
        }}
      />

      <LeadDeleteModal
        lead={selectedLead}
        open={!!selectedLead}
        onClose={() => {
          setSelectedLead(undefined);
        }}
        page={page}
      />
    </>
  );
};

export default LeadTable;
