import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";

import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { validateLead } from "../Lead.Validate";
import LeadDialog from "../LeadDialog";
import {
  allLeads,
  leadById,
  updateLead,
} from "../../../../Redux/Features/Lead-Slice";

const LeadEdit = ({
  isUpdate,
  leadData,
  setIsUpdate,
  onClose,
  isDetailUpdate,
  setIsDetailUpdate,
}: {
  isUpdate?: boolean;
  leadData?: any;
  setIsUpdate?: Function;
  onClose?: Function;
  isDetailUpdate?: boolean;
  setIsDetailUpdate?: Function;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();

  //   const { viewState:LeadById } = useSelector((state: AppState) => state?.leadById);
  //  const {viewState:LeadEdit}= useSelector((state: AppState) => state?.leadEdit);
  useEffect(() => {
    if (isSubmit) {
      if (isDetailUpdate) {
        setIsSubmit(false);

        setIsDetailUpdate?.(false);
        dispatch(leadById({ id: leadData?._id }));
      } else {
        onClose?.();

        setIsUpdate?.(false);
        setIsSubmit(false);
        dispatch(allLeads({ page: 1, limit: 5 })).unwrap();
      }
    }
  }, [isSubmit]);

  return (
    <div>
      <Formik<any>
        initialValues={{
          firstName: leadData?.Lead?.firstName || "",
          leadStatus: leadData?.Lead?.leadStatus || "",
          lastName: leadData?.Lead?.lastName || "",
          phone: leadData?.Lead?.phone || "",
          company: leadData?.Lead?.company || "",
          email: leadData?.Lead?.email || "",
          title: leadData?.Lead?.title || "",
          rating: leadData?.Lead?.rating || "",
          followUp: leadData?.Lead?.followUp || false,
          street: leadData?.Lead?.street || "",
          website: leadData?.Lead?.website || "",
          city: leadData?.Lead?.city || "",
          state: leadData?.Lead?.state || "",
          zipCode: leadData?.Lead?.zipCode || "",
          country: leadData?.Lead?.country || "",
          noOfEmployee: leadData?.Lead?.noOfEmployee || "",
          leadSource: leadData?.Lead?.leadSource || "",
          annualRevenue: leadData?.Lead?.annualRevenue || "",
          industry: leadData?.Lead?.industry || "",
          description: leadData?.Lead?.description || "",
        }}
        onSubmit={(values, { resetForm }) => {
          dispatch(
            updateLead({
              leadData: {
                firstName: values?.firstName,
                leadStatus: values?.leadStatus,
                lastName: values?.lastName,
                phone: values?.phone,
                company: values?.company,
                email: values?.email,
                title: values?.title,
                rating: values?.rating,
                followUp: values?.followUp,
                street: values?.street,
                website: values?.website,
                city: values?.city,
                state: values?.state,
                zipCode: values?.zipCode,
                country: values?.country,
                noOfEmployee: values?.noOfEmployee,
                leadSource: values?.leadSource,
                annualRevenue: values?.annualRevenue,
                industry: values?.industry,
                description: values?.description,
                document: values?.document,
              },
              id: leadData?._id,
            })
          ).then(async (data: any) => {
            if (data?.payload?.success) {
              resetForm();
              setIsSubmit(true);
            }
          });
        }}
        validate={(values) => validateLead(values, t)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isValid,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box className="createFormData createNewForm">
              <LeadDialog
                isUpdate={isUpdate}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                leadDetail={leadData}
                setIsUpdate={setIsUpdate}
                onClose={onClose}
                isDetailUpdate={isDetailUpdate}
                setIsDetailUpdate={setIsDetailUpdate}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LeadEdit;
