import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_URL;

// Login Post request
async function login(username: string, password: string) {
  try {
    const { data } = await axios.post<any>(`${baseURL}/user/login`, {
      username,
      password,
    });
    if (data) {
      localStorage.setItem("bearerToken", data.token);
      localStorage.setItem("refreshToken", data.refreshToken);
      localStorage.setItem("role", data.role);
      localStorage.setItem("email", data?.user?.email);
    }
    return data;
  } catch (e: any) {
    return {
      message:
        e.response.data.err ||
        e.response.data.message ||
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.eror,
    };
  }
}

async function userProfile(email: string) {
  try {
    const { data } = await axios.post<any>(`${baseURL}/user/getUser`, {
      email,
    });
    return data;
  } catch (e: any) {
    return {
      message:
        e.response.data.err ||
        e.response.data.message ||
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.eror,
    };
  }
}

const logout = async () => {
  try {
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    return true;
  } catch (e) {
    return e;
  }
};

//Export Auth Service
const AuthService = {
  login,
  userProfile,
  logout,
};

export default AuthService;
