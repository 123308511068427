import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AppUrls } from "./AppUrl";

import PublicRoute from "./Routes/Public.Route";
import PrivateRoute from "./Routes/Private.Routes";

import SignIn from "./Pages/Public/SignIn/SignIn";
import Dashboard from "./Pages/Private/Dashboard/Dashboard";
import ForgetPassword from "./Pages/Public/ForgetPassword/ForgetPassword";
import NotFound from "./Pages/NotFound/NotFound";
import Layout from "./Component/Layout/Layout";
import Leads from "./Pages/Private/Leads/Leads";
import LeadDetails from "./Pages/Private/Leads/LeadDetails/LeadDetails";
// import LeadDetailTitle from "./Pages/Private/Leads/LeadDetails/LeadDetailTitle";

const AppRoute = () => {
  const [isCreate, setIsCreate] = useState<any>(false);
  return (
    <React.Fragment>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path={AppUrls.PublicURL.SignIn} element={<SignIn />} />
          <Route
            path={AppUrls.PublicURL.ForgetPassword}
            element={<ForgetPassword />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route element={<Layout setIsCreate={setIsCreate} />}>
            <Route
              path={AppUrls.PrivateURL.Dashboard}
              element={<Dashboard />}
            />
            <Route
              path={AppUrls.PrivateURL.AllLeads}
              element={<Leads isCreate={isCreate} setIsCreate={setIsCreate} />}
            />
            <Route
              path={AppUrls.PrivateURL.LeadDetails}
              element={<LeadDetails />}
            />
          </Route>
          {/* <Route element={<LeadDetailTitle />}> */}
            
          {/* </Route> */}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
};

export default AppRoute;
