import React, { ButtonHTMLAttributes } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";

interface NextButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  color?: "white" | "black" | "primary";
  startIcon?: any;
}

export const NextButton = ({
  children,
  color,
  loading,
  style,
  ...rest
}: NextButtonProps & { loading?: boolean }) => {
  return (
    <Button
      // color={
      //   color === "white" ? "white" : color === "black" ? "inherit" : "primary"
      // }
      variant="contained"
      sx={{
        borderRadius: "31px",
        fontWeight: 600,
        padding: "11px 45px",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        transition: "all 0.3s ease",
        verticalAlign: "middle",
        "&:hover": {
          color: "#fff",
        },
        ...style,
      }}
      {...rest}
    >
      <span style={{ opacity: loading ? 0 : 1 }}>{children}</span>
      {loading && (
        <span
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" size={20} thickness={5} />
        </span>
      )}
    </Button>
  );
};
