import { FormikErrors } from "formik";
import { TFunction } from "i18next";
import {
  ValidField,
  validationResult,
} from "../../../Constraints/Shared.Validation";

export function validateLead(values: any, t: TFunction): FormikErrors<any> {
  return validationResult({
    rating: ValidateRating(values.rating, t),
    description: ValidateDescription(values.description, t),
    city: ValidateCity(values.city, t),
    firstName: ValidateFirstName(values.firstName, t),
    lastName: ValidateLastName(values.lastName, t),
    phone: ValidatePhone(values.phone, t),
    title: ValidateTitle(values.title, t),
    street: ValidateStreet(values.street, t),
    state: ValidateState(values.state, t),
    website: ValidateWebsite(values.website, t),
    annualRevenue: ValidateAnnualRevenue(values.annualRevenue, t),
    noOfEmployee: ValidateEmployees(values.noOfEmployee, t),
    country: ValidateCountry(values.country, t),
    zipCode: ValidateZipCode(values.zipCode, t),
    company: ValidateCompany(values.company, t),
    email: ValidateEmail(values.email, t),
    leadStatus: ValidateStatus(values.leadStatus, t),
    leadSource: ValidateLeadSource(values.leadSource, t),
    industry: ValidateIndustry(values.industry, t),
    followUp: ValidateFollowUp(values.followUp, t),
  });
}

export const ValidateFollowUp = (followUp: boolean, t: TFunction) => {
  if (!followUp) {
    return t("lang.IsRequired", {
      field: t("lang.FollowUp"),
    });
  }
  return ValidField;
};

export const ValidateRating = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.Rating"),
    });
  }
  return ValidField;
};

export const ValidateLeadSource = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.LeadSource"),
    });
  }
  return ValidField;
};

export const ValidateIndustry = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.Industry"),
    });
  }
  return ValidField;
};
export const ValidateDescription = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.Description"),
    });
  }
  return ValidField;
};

export const ValidateCity = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.City"),
    });
  }
  return ValidField;
};

export const ValidateFirstName = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.FirstName"),
    });
  }
  return ValidField;
};

export const ValidateLastName = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.LastName"),
    });
  }
  return ValidField;
};

export const ValidatePhone = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.Phone"),
    });
  }
  return ValidField;
};

export const ValidateTitle = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.Title"),
    });
  }
  return ValidField;
};

export const ValidateStreet = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.Street"),
    });
  }
  return ValidField;
};

export const ValidateState = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.StateProvince"),
    });
  }
  return ValidField;
};

export const ValidateWebsite = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.Website"),
    });
  }
  return ValidField;
};

export const ValidateAnnualRevenue = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.AnnualRevenue"),
    });
  }
  return ValidField;
};

export const ValidateEmployees = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.NoOfEmployee"),
    });
  }
  return ValidField;
};

export const ValidateCountry = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.Country"),
    });
  }
  return ValidField;
};

export const ValidateZipCode = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.ZipPostalCode"),
    });
  }
  return ValidField;
};

export const ValidateEmail = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.Email"),
    });
  }
  return ValidField;
};

export const ValidateCompany = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.Company"),
    });
  }
  return ValidField;
};

export const ValidateStatus = (value: string, t: TFunction) => {
  if (!value) {
    return t("lang.IsRequired", {
      field: t("lang.LeadStatus"),
    });
  }
  return ValidField;
};

// export const ValidateDocument = (value: string, t: TFunction) => {
//   if (!value) {
//     return t("lang.IsRequired", {
//       field: 'Document',
//     })
//   }
//   return ValidField
// }
