import React from "react";
import { Box, Button, TextField } from "@mui/material";
import XangeLogo from "../../../Assets/Images/SVG/xange-logo.svg";
import "./ForgetPassword.scss";

const ForgetPassword = () => {
  return (
    <Box>
      <Box className="containerForget">
        <Box className="containerForget__inner">
          <Box>
            <img src={XangeLogo} alt="Xange Logo" />
          </Box>

          <form className="containerForget__form">
            <TextField id="outlined-basic" label="Email" variant="outlined" />

            <Box className="containerForget__btn">
              <Button variant="contained" className="formBtn">
                Send Email
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgetPassword;
