import React, { useState } from "react";
import { Box, } from "@mui/material";
// import { topTabsData } from "./sidebarData";
import { RootState } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
// import { ReactComponent as Logo } from "../../Assets/Images/SVG/greenLogoIcon.svg";
import { Close } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
// import { SignOut } from "../../Redux/Features/Auth-Slice";
// import { clearOrganizationList } from "../../Redux/Features/dashboard-slice";

import "./SideBar.scss";
import LogoSmall from "../../Assets/Images/SVG/LogoSmall.svg";
import dashboardIcon from "../../Assets/Images/PNG/dashboardIcon.png";
import userIcon from "../../Assets/Images/PNG/userIcon.png";
import { useTranslation } from "react-i18next";

interface SingleTabProps {
  label: string;
  Icon: any;
  selected: string;
  path: string;
  setSelected: (e: string) => void;
}

const SingleTab = ({ label, path, setSelected, Icon }: SingleTabProps) => {
  const location = useLocation();
  const navigation = useNavigate();

  const activePath = (path: any) => {
    const url = location.pathname;

    if (path === url) {
      return true;
    } else if (path === `/${url.split("/")[1]}`) {
      return true;
    } else return false;
  };

  return (
    <Box className="menuItem">
      <span
        className="menuItem__link"
        onClick={() => {
          navigation(path);
          setSelected(label);
        }}
        style={{
          background: activePath(path) ? "#FFFFFF29" : "transparent",
        }}
      >
        <img src={Icon} />
        <span>{label}</span>
      </span>
    </Box>
  );
};

interface ChildProps {
  openMenu: boolean;
  menuBtnEvent: any;
  openSideBar:boolean;
  setOpenSideBar:Function
}

const Sidebar: React.FC<ChildProps> = ({
  openMenu,
  menuBtnEvent,
  openSideBar,
  setOpenSideBar
}: ChildProps) => {
  const [selected, setSelected] = useState("");
  //   const userData = useSelector((state: RootState) => state.Auth.user);

  const navigation = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = (e: any) => {
    e.preventDefault();
    // dispatch(SignOut());
  };
  const { t } = useTranslation();

  const topTabsData = [
    { icon: dashboardIcon, name: t("lang.Dashboard"), path: "/" },
    { icon: userIcon, name: t("lang.AllLeads"), path: "/leads" },
  ];

  return (
    <>
      <Box
        className={openMenu  === false ? "sidebar" : "sidebar sidebar--active"}
      >
        <Box className="sidebarContainer">
          <Box
            className="mobileMenuBtn"
            onClick={() =>
              {
                setOpenSideBar(false)
              menuBtnEvent()}}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            style={{ cursor: "pointer" }}
          >
            <Close />
          </Box>
          <Box className="sidebar__logo">
            <img src={LogoSmall} />
            <span>Xange.com</span>
          </Box>
          <Box className="sidebar__menu">
            {topTabsData?.map((el: any) => {
              return (
                <SingleTab
                  label={el.name}
                  Icon={el.icon}
                  path={el.path}
                  selected={selected}
                  setSelected={setSelected}
                  key={el.name}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Sidebar;
