import { TFunction } from "i18next";

import { FormikErrors } from "formik";
import { LoginValues } from "./SignIn";
import {
  ValidField,
  validationResult,
} from "../../../Constraints/Shared.Validation";

export function validateAuth(
  values: LoginValues,
  t: TFunction
): FormikErrors<LoginValues> {
  return validationResult({
    username: validateUsername(values, t),
    password: validatePassword(values, t),
  });
}

const validateUsername = (values: LoginValues, t: TFunction) => {
  if (!values.username) {
    return t("{{field}} is a required field", {
      field: "Username",
    });
  }
  return ValidField;
};

const validatePassword = (values: LoginValues, t: TFunction) => {
  if (!values.password) {
    return t("{{field}} is a required field", {
      field: "Password",
    });
  }
  return ValidField;
};
