import { configureStore } from "@reduxjs/toolkit";
import leadsSlice from "./Features/Lead-Slice";
import userSlice from "./Features/Auth-Slice";

// Configure Store Here
const Store = configureStore({
  reducer: {
    user: userSlice.reducer,
    leads: leadsSlice.reducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;

export default Store;
